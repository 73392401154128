<template>
  <div id="app">
      <div v-if="isLoggedIn">
        <Debug v-if="debugMode"></Debug>
        <SessionTimer />
        <div class="container">
          <nav class="navbar navbar-expand">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item">
                <router-link to="/settings" class="nav-link">Settings</router-link>
              </li>
              <li class="nav-item" v-if="isCenshareUrlSet">
                <router-link to="/schema-manager" class="nav-link">Schema Manager</router-link>
              </li>
              <li class="nav-item" v-if="isCenshareUrlSet">
                <router-link to="/entity-viewer" class="nav-link">Entity Viewer</router-link>
              </li>
            </ul>
          </nav>
          <router-view/>
        </div>
      </div>
    <div v-else class="cenflow-logo">
      <span class="helper"></span>
    </div>
  </div>
</template>

<script>
import SweetAlertService from "@/services/sweetalert";
import Debug from "@/components/Debug";
import SessionTimer from "@/components/SessionTimer";

export default {
  name: 'App',
  components: {Debug, SessionTimer},
  data() {
    return {
      debugMode: true,
      message: null,
      loader: null,
      client: {
        id: null,
        name: null,
        url: null
      }
    }
  },
  computed: {
    isLoggedIn() {
      console.log('LoggedIn', this.$store.state.auth.loggedIn === true)
      return this.$store.state.auth.loggedIn === true;
    },
    isCenshareUrlSet() {
      return typeof this.$store.state.auth.censhareClient.url !== 'undefined'
          && this.$store.state.auth.censhareClient.url !== null;
    }
  },
  mounted() {
    this.jwtLogin()
  },
  methods: {
    async jwtLogin() {
      this.loader = this.$loading.show({
        container: null,
        canCancel: false,
        loader: 'dots',
        opacity: 0
      });

      this.$store.dispatch("auth/login").then(
          () => {
            this.loader.hide()
          },
          (err) => {
            this.loader.hide()
            this.$swal(SweetAlertService.error(err))
          }
      );
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 20px;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
nav {
  display: block;

  .nav-link {
    box-shadow: 1px 1px 3px rgb(97 94 94 / 25%);
    padding: 10px 20px;
    display: inline-block;
    color: #333333;
    text-decoration: none;
    margin-bottom: 20px;
    margin-right: 10px;

    &:hover, &:active, &:focus {
      color: #333333;
    }

    &.router-link-active {
      background: #e6e6e6;
    }
  }
}

.buttons {
  clear: both;
  text-align: right;
}

.btn {
  margin: 10px;
  display: inline-block;
  padding: 12px 18px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.1;
  color: #333333;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: inherit;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 0 !important;
  border-radius: 4px;

  &:hover {
    background: #c5c5c5;
  }
}
.cenflow-logo {
  height: 100%;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;

  text-align: center;
  margin: -60px 0 1em;

  .helper {
    display: inline-block;
    height: 100vh;
    vertical-align: middle;
    overflow: hidden;
  }

  img {
    vertical-align: middle;
  }
}
</style>
