<template>
  <Form @submit="handleSubmit" :validation-schema="schema">
    <div class="form-group">
      <label>Censhare HCMS Url</label>
      <Field name="censhareUrl"  type="text" v-model="censhareClient.url"  v-slot="{ field }">
        <input v-bind="field" class="form-control" placeholder="Censhare HCMS Url">
      </Field>
      <ErrorMessage name="censhareUrl" class="error-feedback" />
    </div>

    <div class="form-group">
      <label>Username</label>
      <Field name="username"  type="text" v-model="censhareClient.username"  v-slot="{ field }">
        <input v-bind="field" class="form-control" placeholder="Username">
      </Field>
      <ErrorMessage name="username" class="error-feedback" />
    </div>

    <div class="form-group">
      <label>Password</label>
      <Field name="password"  v-model="censhareClient.password" v-slot="{ field }">
        <input v-bind="field" class="form-control" placeholder="Password" type="password">
      </Field>
      <ErrorMessage name="password" class="error-feedback" />
    </div>

    <div class="form-group buttons">
      <button class="btn btn-primary btn-block" :disabled="loading" :style="clientColor">
            <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
            ></span>
        <span>Submit</span>
      </button>
    </div>

  </Form>
</template>

<script>
import SweetAlertService from '../services/sweetalert';
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: 'BasicSettings',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      censhareUrl: yup.string().required("Censhare url is required!"),
      username: yup.string(),
      password: yup.string(),
    });

    return {
      loading: false,
      censhareClient: {...this.$store.state.auth.censhareClient},
      schema,
    };
  },
  computed: {
    clientColor() {
      return {
        'backgroundColor': this.$store.state.auth.jobFlowClient.background_color,
        'color': this.$store.state.auth.jobFlowClient.text_color
      }
    }
  },
  methods: {
    handleSubmit(settings) {
      this.loading = true;
      this.$store.dispatch("auth/settings", settings).then(
          (response) => {
            this.loading = false;
            this.$swal(SweetAlertService.success(response.message))
          },
          (err) => {
            this.loading = false;
            this.$swal(SweetAlertService.error(err))
          }
      );
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.margin-bottom {
  margin-bottom: 15px;
}

.fade-enter, .fade-leave-active {
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.input {
  padding: 5px 10px;
  margin-bottom: 10px;
  clear: both;
}

.error-feedback {
  font-size: 12px;
}

.form-group {
  margin-top: 20px
}

label {
  float: left;
  display: block;
}
.btn {
  margin-right: 0;
}
</style>
